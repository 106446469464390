
// Redirect.js
import React, { useEffect } from 'react';
import './Redirect.css';

const Redirect = () => {
  useEffect(() => {
    // Redirect to the affiliate website after a delay
    const timer = setTimeout(() => {
      window.location.replace('https://www.oke888.co/?referral=jj39491');
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="redirect-page">
      <div className="redirect-container">
        <h1 className="redirect-heading">Hold Tight!</h1>
        <p className="redirect-message">
          We are redirecting you to our trusted partner's website.
          Please wait a moment...
        </p>
        <div className="redirect-spinner"></div>
      </div>
    </div>
  );
};

export default Redirect;