import React, { useState, useEffect } from 'react';
import okebetHeader from './images/OKEBET-HEADER.jpg';
import './Homepage.css';

const Homepage = () => {

    
  const [deferredPrompt, setDeferredPrompt] = useState(null);


  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleWebsiteClick = () => {
    window.open('https://www.oke888.co/?referral=jj39491', '_blank');
  };

  return (
    <div className="homepage">
      {/* Black Container with Centered Text */}
      
      {/* White Container with Image, Details, and Buttons */}
      <div className="white-container">
        <img src={okebetHeader} alt="Casino Logo" className="casino-image" />
        <h2 className='heading'> Upto 50% Cashback Welcome Bonus</h2>
        <p className="casino-description">
        Join Okebet today and boost your first deposit with our Welcome Bonus! Plus, enjoy Cashback on your losses to keep the fun going. Don’t miss out on these rewards—install the app and start winning now!
        </p>
        <div className="buttons">
          <button onClick={handleInstallClick} disabled={!deferredPrompt} className=" animated">
            Install App
          </button>
          <button onClick={handleWebsiteClick} className="website-button animated">
            Go to Website
          </button>
        </div>
      </div>
    </div>
  );
};

export default Homepage;